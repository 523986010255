import React, { useContext } from 'react';
import { Link, graphql, navigate } from 'gatsby';
import BodyClassName from 'react-body-classname';

import { formatTitle } from '../../utils/shop';
import ArrowLeft from '../../images/arrow-left.svg';
import { getVideoUrl } from '../../utils/functions';
import { getSliceNumber } from '../../utils/podCast';
import SocialShare from '../../components/Shop/SocialShare';
import { AppContext } from '../../apollo/wrap-root-element';
import RelatedContent from '../../components/podcast/RelatedContent';
import DefaultLayoutComponent from '../layouts/DefaultLayoutComponent';
import PeopleDescriptions from '../../components/podcast/PeopleDescriptions';
import PodCastTopSliderItem from '../../components/podcast/PodCastTopSliderItem';
import PodcastExternalLinks from '../../components/PodcastExternalLinks/PodcastExternalLinks';
import Stickysidebar from '../../components/Read/Stickysidebar';

const isListEmpty = (arr) => {
  if (Array.isArray(arr) && !!arr?.length) {
    return false;
  }

  return true;
};

const isFurtherReadingEmpty = (arr) => {
  if (Array.isArray(arr) && !!arr?.length) {
    if (Array.isArray(arr[0].reading) && !!arr[0].reading.length) {
      return false;
    }

    return true;
  }

  return true;
};
const PodCastItemPageComponent = ({ data }) => {
  const {
    podCast: { nodes },
    totalWatchData,
    readData,
    podCastData,
  } = data;
  const { selectedPodCast, setSelectedPodcast, isAudioPause, SetIsAudioPause } = useContext(AppContext);

  const [singlePodCastData] = nodes;
  const furtherReading = singlePodCastData.data.furtherReading;
  const footnotes = singlePodCastData.data.footnotes;
  const videoUrl = singlePodCastData.data.videoUrl?.embed_url;

  const relatedListen = singlePodCastData.data.relatedListen
    ?.filter((content) => content?.relatedListen?.uid)
    .map((item) => {
      const pod = podCastData.nodes.find((pod) => pod.uid === item.relatedListen.uid);
      return {
        ...pod,
        category: 'LISTEN',
        url: `/podcast/${pod.uid}`,
      };
    });

  const relatedEpisodes = singlePodCastData.data.relatedEpisodes
    ?.filter((content) => content?.episode?.uid)
    .map((item) => {
      const watch = totalWatchData.nodes.find((watch) => watch.uid === item.episode.uid);

      return {
        ...watch,
        category: 'WATCH',
        uid: item.episode.uid,
      };
    });

  const relatedRead = singlePodCastData.data.relatedRead
    ?.filter((content) => content?.read?.uid)
    .map((item) => {
      const read = readData.nodes.find((read) => read.uid === item?.read?.uid);
      return {
        ...read.data,
        category: 'READ',
        url: read.url,
      };
    });

  const relatedShop = singlePodCastData.data.relatedProducts
    .filter((item) => !!item.product)
    .map((p) => {
      return {
        thumbnailUrl: p.product.image.src,
        category: 'SHOP',
        title: p.product.title,
        url: `/product/${formatTitle(p.product.title)}`,
      };
    });

  const relatedReadCount = relatedRead.length;
  const relatedEpisodesCount = relatedEpisodes.length;
  const relatedShopCount = relatedShop.length;
  const relatedListenCount = relatedListen.length;

  let relatedData = [];

  if (relatedEpisodes.length) {
    const sliceNumber = getSliceNumber(relatedEpisodesCount, relatedShopCount, relatedListenCount, relatedData);
    relatedData = relatedData.concat(relatedEpisodes.slice(0, sliceNumber));
  }
  if (relatedRead.length) {
    const sliceNumber = getSliceNumber(relatedEpisodesCount, relatedShopCount, relatedListenCount, relatedData);
    relatedData = relatedData.concat(relatedRead.slice(0, sliceNumber));
  }

  if (relatedShop.length) {
    const sliceNumber = getSliceNumber(relatedReadCount, relatedEpisodesCount, relatedListenCount, relatedData);
    relatedData = relatedData.concat(relatedShop.slice(0, sliceNumber));
  }
  if (relatedListenCount) {
    const sliceNumber = getSliceNumber(relatedReadCount, relatedEpisodesCount, relatedShopCount, relatedData);
    relatedData = relatedData.concat(relatedListen.slice(0, sliceNumber));
  }
  const videoId = videoUrl?.split('youtu.be/')[1];
  return (
    <BodyClassName className="body-dark page-watch page-watch--video page-read--single podcast">
      <DefaultLayoutComponent title={singlePodCastData.data.podCastTitle[0].text}>
        <div className="site-main">
          <div className="bg-transparent" style={{ position: 'relative' }}>
            <div className="wrapper wrapper-xl pd-x-md">
              <div className="section r_s_title bg-light pd-t-0">
                <div className="wrapper wrapper-lg pd-x-sm">
                  <div className="watch-bc" style={{ paddingTop: '40px', paddingBottom: '40px' }}>
                    <div className="bc-holder" onClick={() => navigate(-1)}>
                      <img src={ArrowLeft} />
                      <p style={{ color: '#656565' }}>Back</p>
                    </div>
                  </div>

                  <div
                    onClick={() => {
                      setSelectedPodcast(singlePodCastData);
                      SetIsAudioPause(!isAudioPause);
                    }}
                  >
                    <PodCastTopSliderItem
                      podCast={singlePodCastData}
                      showWaterMark={true}
                      isPlay={selectedPodCast?.uid === singlePodCastData.uid && !isAudioPause}
                    />
                  </div>
                  <div
                    className="wrapper wrapper-md  pd-x-sm"
                    style={{ color: 'black', marginTop: '41px', marginBottom: '43px' }}
                  >
                    <div>
                      <h2 className="descriptions-title">Descriptions</h2>
                      <div>
                        {singlePodCastData.data.descriptions.map((item) => (
                          <p className="descriptions" style={{ marginTop: '10px' }}>
                            {' '}
                            {item.text}
                          </p>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="people-description">
                <div className="wrapper wrapper-lg pd-x-sm">
                  <div className="wrapper wrapper-md  pd-x-sm">
                    <PeopleDescriptions
                      dataActionPhoto={singlePodCastData.data.intervieweeProfilePhoto.url}
                      dataName={singlePodCastData.data.intervieweeName[0].text}
                      dataMinistryRole={singlePodCastData.data.intervieweeTitles}
                      descriptions={singlePodCastData.data.intervieweeBio}
                      images={singlePodCastData.data.photos}
                    />

                    {videoId && (
                      <div
                        className="carousel-item carousel-video media ratio_21-9"
                        style={{
                          margin: '100px 0',
                        }}
                      >
                        <iframe
                          width="560"
                          height="315"
                          src={`${getVideoUrl(videoId)}?autoplay=0&rel=0`}
                          frameBorder="0"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        ></iframe>
                      </div>
                    )}
                  </div>
                </div>
                <div className="read-footer bg-transparent">
                  <div className="wrapper wrapper-xl pd-x-md">
                    <div className=" r_s_footer-info pd-t-0 pd-x-sm">
                      <div className="wrapper wrapper-md pd-x-sm">
                        <div className="">
                          <SocialShare title={singlePodCastData.data.podCastTitle.text} />
                          {!isFurtherReadingEmpty(furtherReading) && (
                            <div className="r_s_author">
                              <h6 className="r_s_author-name">Further Reading</h6>
                              {furtherReading?.map((fReading) =>
                                fReading?.reading?.length ? (
                                  <p className="r_s_author-desc">{fReading?.reading[0].text}</p>
                                ) : null
                              )}
                            </div>
                          )}

                          {!isListEmpty(footnotes) && (
                            <>
                              <div
                                style={{
                                  borderTop: '1px solid #404040',
                                  marginBottom: '33px',
                                  marginTop: '50px',
                                }}
                              ></div>
                              <div className="r_s_author">
                                <h6 className="r_s_author-name">Footnotes</h6>
                                {footnotes.map((fnote) => (
                                  <p className="r_s_author-desc">{fnote?.footnote[0].text}</p>
                                ))}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <PodcastExternalLinks />
              </div>
              <div style={{ marginTop: '121px' }}>
                <RelatedContent relatedContent={relatedData} />
              </div>
            </div>
          </div>
        </div>
      </DefaultLayoutComponent>
    </BodyClassName>
  );
};

export default PodCastItemPageComponent;

export const query = graphql`
  query PodCastSinglePage($uid: String!) {
    podCast: allPrismicPodcast(filter: { uid: { eq: $uid } }) {
      nodes {
        data {
          podcastDuration: podcast_duration
          thumnailImage: thumbnail_image {
            url
          }
          podCastTitle: podcast_title {
            text
          }
          season {
            type
            uid
          }
          podcastId: podcast_id {
            text
          }
          descriptions: podcast_description {
            text
            type
          }

          miniPlayerThumbNail: mini_player_thumbnail {
            url
          }
          furtherReading: further_reading {
            reading {
              text
            }
          }
          intervieweeBio: interviewee_bio {
            type
            text
          }
          intervieweeName: interviewee_name {
            text
          }
          intervieweeProfilePhoto: interviewee_profile_photo {
            url
          }
          intervieweeTitles: interviewee_titles {
            text
          }
          photos {
            photo {
              url
            }
          }
          videoUrl: video_url {
            embed_url
          }
          relatedEpisodes: related_episodes {
            episode {
              uid
              type
              slug
            }
          }
          relatedRead: related_reads {
            read {
              uid
              type
              slug
            }
          }
          relatedListen: related_listens {
            relatedListen: related_listen {
              uid
              id
              type
            }
          }
          relatedProducts: related_products {
            product {
              title
              image {
                src
              }
            }
          }
        }
        uid
      }
    }
    totalWatchData: allPrismicEpisodes(sort: { fields: data___priority, order: ASC }) {
      nodes {
        data {
          title {
            text
          }
          videoDuration: video_duration {
            text
          }
          thumbnailImage: thumbnail_image {
            url
          }
          youtubeId: youtube_id {
            text
          }
        }
        uid
      }
    }
    podCastData: allPrismicPodcast(sort: { fields: first_publication_date, order: DESC }) {
      nodes {
        data {
          thumnailImage: thumbnail_image {
            url
          }
          podCastTitle: podcast_title {
            text
          }
          season {
            type
            uid
          }
          podcastDuration: podcast_duration
        }
        uid
      }
    }
    readData: allPrismicRead(sort: { fields: data___priority }) {
      nodes {
        data {
          title {
            text
          }
          readDuration: read_duration1 {
            text
          }
          featuredImage: featured_image {
            alt
            url
            fluid(maxWidth: 600) {
              ...GatsbyPrismicImageFluid
            }
          }
        }
        url
        uid
      }
    }
  }
`;
